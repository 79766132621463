body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  margin-right: 10px;
  padding: 10px;
}

button {
  cursor: pointer;
}

table {
  margin: 25px 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  flex-grow: 1; /* Allows this container to grow and take up available space */
  margin: 40px;
  min-height: 0; /* Prevents the container from stretching past its content */
  padding-bottom: 40px;
}

.footer {
  text-align: center;
  padding: 20px 0;
  margin-top: auto; /* Pushes the footer to the bottom */
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tx-table-row td {
  padding: 0 8px;
}

.block-nav {
  margin: 0px 5px;
}

.search-btn {
  padding: 10px;
}

.oracle-table {
  border-collapse: collapse;
  border: 1px solid black;
}

.oracle-table th, .oracle-table td {
  border: 1px solid black;
}

.recent-blocks {
  border-collapse: collapse;
  border: 1px solid black;
}

.recent-blocks th, .recent-blocks td {
  border: 1px solid black;
  padding: 4px 4px;
}

.hoverable-row:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.block-link {
  color: black;
  text-decoration: none;
}

.base-link {
  color: black;
  text-decoration: none;
}

.base-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1166ee;
}

.personal-link {
  text-decoration: none;
}

.personal-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1166ee;
}

.block-details p {
  margin: 8px 0;
}

.icon-align {
  display: flex;
  align-items: center; /* This centers the content vertically */
}

.icon-spacing {
  margin-left: 5px;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.l2book tr {
  font-size: 12px;
  text-align: center;
}

.l2book td {
  padding: 0 2px;
  border: none;
}

.l2book-top-level {
  border-collapse: collapse;
}

.book-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjust 200px as needed */
  gap: 20px;
  justify-content: center;
  height: 100%;
}

.banner {
  background-color: #6ec871 !important; /* Example background color */
  color: white; /* Text color */
  padding: 15px;
  text-align: center;
  margin-bottom: 20px; /* Spacing below the banner */
}

.last-th {
  padding: 0;
  border-spacing: 0;
}

.pr-0 {
  padding-right: 0;
  padding-left: 8px;
}

.bold {
  font-weight: 800;
}

.red {
  color: red;
}